.Footer {
  height: 110px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%), #101924;
}

@media (max-width: 1035px) {
  .Footer {
    height: 125px;
  }
  .Footer-Container {
    display: block;
    text-align: center;
  }
  .Footer-Icon {
    img {
      width: 130px;
      margin: 20px auto 10px auto;
    }
  }
}